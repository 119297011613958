// @ts-check
import {makeStyles} from "tss-react/mui";

export default makeStyles()((/** @type AllCustomTheme */ theme) => ({
    title: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "40px 40px 16px",
        fontSize: "23px",
        textTransform: "none",
        fontWeight: theme.typography.fontWeightMedium
    },
    content: {
        "padding": "2.5rem 2.5rem 3.75rem !important",
        "& table": {
            width: "unset !important"
        },
        "& th": {
            background: theme.palette.background.paper
        }
    },
    buttonManage: {
        textTransform: "capitalize",
        color: theme.palette.primary.main
    },
    overlapIconWrapper: {
        height: "22px",
        display: "flex",
        alignItems: "center"
    },
    icon: {
        cursor: "pointer"
    }
}));
