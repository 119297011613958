// @ts-check
import {Lock, Warning} from "@mui/icons-material";
import {bool, string} from "prop-types";
import React from "react";

import config from "../../../../config/config.json";
import useStyles from "../op_backlog_view.styles";

/**
 * OpStatus component
 *
 * @param {object} props
 * @param {string} [props.status]
 * @param {boolean} props.isCutOver
 * @param {boolean} props.isLocked
 * @return {JSX.Element}
 */
const OpStatus = ({status, isCutOver, isLocked}) => {
    const {classes} = useStyles();
    const {
        TEXT_PUNCTUATION: {HYPHEN}
    } = config;

    return (
        <div className={classes.opStatus}>
            <span>{status || (isCutOver ? HYPHEN : <Warning className={classes.errorIcon} />)}</span>
            {isLocked && <Lock className={classes.lockIcon} />}
        </div>
    );
};

OpStatus.propTypes = {
    status: string,
    isCutOver: bool.isRequired,
    isLocked: bool.isRequired
};

export default OpStatus;
