// @ts-check
import {string} from "prop-types";
import React, {useContext} from "react";
import {useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {selectPatientInfo} from "../../private_data/private_data_selectors";
import buildPatientInfo from "../../private_data/utils/build_patient_info";
import useStyles from "../op_backlog_view.styles";

/**
 * render PatientDetails component
 * @param {Object} props
 * @param {string} props.id
 * @return {React.ReactElement}
 */
const PatientDetails = ({id}) => {
    const {classes} = useStyles();
    const {getLuxonToken} = useContext(DateContext);

    const patientInfo = useSelector((state) => selectPatientInfo(state, {id}));

    const {name, birthDate, gender} = buildPatientInfo(patientInfo, id, getLuxonToken(DATE_FORMATS.BIRTH_DATE_FORMAT));
    const birthDateAndGender = `(${birthDate}) ${gender}`;
    return (
        <div className={classes.patient}>
            <span title={name}>{name}</span>
            <span title={birthDateAndGender}>{birthDateAndGender}</span>
        </div>
    );
};
PatientDetails.propTypes = {
    id: string.isRequired
};
export default PatientDetails;
