// @ts-check
import {Button} from "@mui/material";
import {bool, func, object} from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {useFetchPriorities} from "../../../hooks/useFetchPriorities";
import {toggleExpandFilterAction} from "../../../pages/op_backlog/op_backlog_actions";
import {
    selectOpBacklogFilterExpand,
    selectOpBacklogHealthcareServiceList,
    selectOpBacklogSurgeryNamesList,
    selectPersonIds,
    selectPractitionerIdsByCategories
} from "../../../pages/op_backlog/op_backlog_selectors";
import {selectCurrentOrganizationId} from "../../../redux/app_selectors";
import {sortArrayOfObjectsByKey} from "../../../utils/sort_array_of_objects_by_key";
import {selectFullNamesArray, selectStandardNamesArray} from "../../private_data/private_data_selectors";
import {selectRoomInfos} from "../../rooms/rooms_selectors";
import {
    ControlledAutocomplete,
    ControlledAutocompleteMultiple,
    ControlledSelectorMultiple,
    ControlledSelectorSingle
} from "../../shared/controlled_form";
import {RHF_BACKLOG_NAMES} from "../helpers";
import useStyles from "../op_backlog_view.styles";
/** @typedef {import('react-hook-form').UseFormGetValues<any>} UseFormGetValues */

/**
 * A form containing all the filters for the surgeries table
 *
 * @param {object} props The props
 * @param {object} props.control The RHF control object
 * @param {Function} props.reset A RHF handler to reset all values
 * @param {UseFormGetValues} props.getValues A RHF handler to get the current form values
 * @param {object} props.errors
 * @param {Boolean} props.isDirty
 * @param {Function} props.setValue
 * @return {React.ReactElement}
 */
export const FiltersFormBacklog = ({control, reset, getValues, errors, isDirty, setValue}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();
    const dispatch = useDispatch();

    // redux
    const surgeryNameList = useSelector(selectOpBacklogSurgeryNamesList);
    const {surgeons: surgeonIds} = useSelector(selectPractitionerIdsByCategories);
    const roomInfos = useSelector(selectRoomInfos);
    const {patientIds} = useSelector(selectPersonIds);
    const organizationId = useSelector(selectCurrentOrganizationId);
    const isFilterExpanded = useSelector(selectOpBacklogFilterExpand);
    const healtcareServices = useSelector(selectOpBacklogHealthcareServiceList);

    const surgeonNamesList = useSelector(selectStandardNamesArray({ids: surgeonIds || [], type: "practitioner"}));
    const patientNamesList = useSelector(selectFullNamesArray({ids: patientIds, type: "patient"}));

    // custom hook for priority
    const {prioritiesList} = useFetchPriorities(organizationId);

    const {
        TEXT_PUNCTUATION: {HYPHEN}
    } = config;

    const surgeryNameListSorted = surgeryNameList
        .map((el) => ({label: el, value: el}))
        .sort((a, b) => sortArrayOfObjectsByKey(a, b, "label"));

    const operatorNameListSorted = surgeonNamesList
        .map(({id, name}) => ({value: id, label: name}))
        .sort((a, b) => sortArrayOfObjectsByKey(a, b, "label"));

    const roomsList = roomInfos.map((room) => ({label: room.name, value: room.id}));

    const healtcareServiceListSorted = healtcareServices
        .map((el) => ({
            value: el.id,
            label: t([`HealthcareService.${el.id}`, el.name]) // fallback if the translation for the healthcareServiceId was not set
        }))
        .sort((a, b) => sortArrayOfObjectsByKey(a, b, "label"));

    const patientNamesSorted = patientNamesList
        .map(({id, name}) => ({label: name, value: name, id})) // set name in value
        .sort((a, b) => sortArrayOfObjectsByKey(a, b, "label"));

    const toggleFilter = () => {
        dispatch(toggleExpandFilterAction());
    };

    const handleReset = () => reset();

    const ageArray = [...Array(121).keys()];
    return (
        <form className={classes.formWrapper}>
            <div className={classes.formRow}>
                <ControlledAutocomplete
                    classesStyle={classes.autocomplete}
                    control={control}
                    items={patientNamesSorted}
                    name={RHF_BACKLOG_NAMES.patientNameFilter}
                    placeholder={t("OpBacklogView.placeholderPatientName")}
                    showSearchIcon
                    title={t("OpBacklogView.patient")}
                />
                <ControlledAutocompleteMultiple
                    classesStyle={classes.autocomplete}
                    control={control}
                    hasTooltip
                    items={surgeryNameListSorted}
                    name={RHF_BACKLOG_NAMES.surgeryNameFilter}
                    placeholder={t("App.pleaseSelect")}
                    title={t("OpBacklogView.surgery")}
                />
                <ControlledSelectorMultiple
                    classesStyle={classes.autocomplete}
                    control={control}
                    dataTestid={`multiple-selector-${RHF_BACKLOG_NAMES.healthcareServiceFilter}`}
                    errors={errors.healthcareServiceFilter}
                    getValues={getValues}
                    items={healtcareServiceListSorted}
                    name={RHF_BACKLOG_NAMES.healthcareServiceFilter}
                    placeholder={t("App.pleaseSelect")}
                    reset={reset}
                    title={t("OpBacklogView.healthcareService")}
                />
                <ControlledAutocompleteMultiple
                    classesStyle={classes.autocomplete}
                    control={control}
                    items={operatorNameListSorted}
                    name={RHF_BACKLOG_NAMES.operatorNameFilter}
                    placeholder={t("App.pleaseSelect")}
                    title={t("OpBacklogView.surgeon")}
                />
                <Button className={classes.showMore} onClick={toggleFilter}>
                    {isFilterExpanded ? t("OpBacklogView.lessFilter") : t("OpBacklogView.moreFilter")}
                </Button>
                <Button className={cx(classes.showMore, classes.ml1)} disabled={!isDirty} onClick={handleReset}>
                    {t("OpBacklogView.allReset")}
                </Button>
            </div>
            {isFilterExpanded && (
                <div className={classes.formRow}>
                    <ControlledSelectorMultiple
                        classesStyle={classes.autocomplete}
                        control={control}
                        dataTestid={`multiple-selector-${RHF_BACKLOG_NAMES.roomFilter}`}
                        errors={errors.roomFilter}
                        getValues={getValues}
                        items={roomsList}
                        name={RHF_BACKLOG_NAMES.roomFilter}
                        placeholder={t("App.pleaseSelect")}
                        reset={reset}
                        title={t("OpBacklogView.room")}
                    />
                    <ControlledSelectorSingle
                        classesStyle={cx(classes.autocomplete, classes.ml1)}
                        control={control}
                        items={prioritiesList.map((item) => ({value: item, label: t(`urgency.${item}`)}))}
                        name={RHF_BACKLOG_NAMES.priorityFilter}
                        placeholder={t("App.pleaseSelect")}
                        setValue={setValue}
                        showResetIcon
                        title={t("OpBacklogView.priority")}
                    />
                    <ControlledSelectorSingle
                        classesStyle={classes.ageFilter}
                        control={control}
                        items={ageArray.map((item) => ({value: item, label: item.toString()}))}
                        name={RHF_BACKLOG_NAMES.patientAgeFrom}
                        placeholder={t("Form.from")}
                        title={t("OpBacklogView.patientAge")}
                    />
                    <span className={classes.hyphen}>{HYPHEN}</span>
                    <ControlledSelectorSingle
                        classesStyle={cx(classes.ageFilter, classes.ml1)}
                        control={control}
                        errors={errors[RHF_BACKLOG_NAMES.patientAgeTo]}
                        hasOwnErrorMessage={false}
                        items={ageArray.map((item) => ({value: item, label: item.toString()}))}
                        name={RHF_BACKLOG_NAMES.patientAgeTo}
                        placeholder={t("Form.to")}
                        title={" "}
                    />
                </div>
            )}
        </form>
    );
};
FiltersFormBacklog.propTypes = {
    control: object.isRequired,
    reset: func.isRequired,
    getValues: func.isRequired,
    errors: object,
    isDirty: bool,
    setValue: func.isRequired
};
