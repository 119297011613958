import {IconButton} from "@mui/material";
import {oneOf} from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {toggleExpandColumnAction} from "../../../pages/op_backlog/op_backlog_actions";
import {selectOpBacklog} from "../../../pages/op_backlog/op_backlog_selectors";
import ExpandIcon from "../../shared/icons/expand_icon";
import FoldIcon from "../../shared/icons/fold_icon";
import useStyles from "../op_backlog_view.styles";

/**
 * Label with expand / fold icon
 * @param {Object} props
 * @return {React.ReactElement}
 */
const LabelWithExpand = ({columnId}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {isColumnExpanded} = useSelector(selectOpBacklog);
    const toggleColumn = (e) => {
        e.stopPropagation();
        dispatch(toggleExpandColumnAction(columnId));
    };

    return (
        <span>
            {t(`OpBacklogView.${columnId}`)}
            <IconButton className={classes.expandFoldIcon} size="large" onClick={toggleColumn}>
                {isColumnExpanded[columnId] ? <FoldIcon /> : <ExpandIcon />}
            </IconButton>
        </span>
    );
};
LabelWithExpand.propTypes = {
    columnId: oneOf(["surgeons", "anesthetist"])
};
export default LabelWithExpand;
