// @ts-check
import {Warning} from "@mui/icons-material";
import {Tooltip} from "@mui/material";
import {bool, number, shape, string} from "prop-types";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {selectOpBacklogById} from "../../../pages/op_backlog/op_backlog_selectors";
import {selectStandardName} from "../../private_data/private_data_selectors";
import useStyles from "../op_backlog_view.styles";

const conflictCode = 509;

/**
 * render SurgeryName component with warning icon
 *
 * @param {Object} props
 * @param {string} props.surgery
 * @param {boolean} props.isCutOver
 * @param {{code: number, msg: string}} props.error
 * @return {React.ReactElement}
 */
const SurgeryName = ({surgery, error, isCutOver}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const {formatFromISO} = useContext(DateContext);

    const conflictOp = useSelector(selectOpBacklogById({id: error?.code === conflictCode ? error.msg : null}));
    const patientName = useSelector(selectStandardName({id: conflictOp?._patient?.id || "", type: "patient"}));

    let date = "";
    let time = "";
    if (error?.code === conflictCode) {
        const startDate = conflictOp?._internalTimestamps?.duraRoomLockPre?.dtStart;
        date = formatFromISO(startDate, DATE_FORMATS.DATE);
        time = formatFromISO(startDate, DATE_FORMATS.TIME);
    }
    const tooltipText = isCutOver
        ? `${error.code}: ${t([`OpBacklogView.cutOverErrorCodes.${error.code}`, `OpBacklogView.errorCodes.${error.code}`], {
              date,
              time,
              patientName
          })}`
        : `${error.code}: ${t([`OpBacklogView.errorCodes.${error.code}`, ""], {date, time, patientName})}`;
    return error?.code ? (
        <div className={classes.error}>
            <Tooltip title={tooltipText}>
                <Warning className={classes.errorIcon} />
            </Tooltip>
            <Tooltip title={surgery}>
                <span className={classes.text}>{surgery}</span>
            </Tooltip>
        </div>
    ) : (
        <Tooltip title={surgery}>
            <span className={classes.text}>{surgery}</span>
        </Tooltip>
    );
};
SurgeryName.propTypes = {
    surgery: string.isRequired, // translation key
    error: shape({
        code: number,
        msg: string
    }),
    isCutOver: bool
};
export default SurgeryName;
