// @ts-check
import {Close} from "@mui/icons-material";
import {Box, Button, DialogContent, DialogTitle} from "@mui/material";
import {array, func} from "prop-types";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {RESOURCE_HISTORY} from "../../../../config/api_config";
import config from "../../../../config/config.json";
import {STATUS_KEY} from "../../../../config/op_status";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {selectOpBacklogResourceHistories} from "../../../pages/op_backlog/op_backlog_selectors";
import {opDisplayStatus} from "../../../utils/op_status";
import {PERMISSION, useSecurity} from "../../../utils/security";
import {selectFeSettings} from "../../fe_settings/fe_settings_selectors";
import {selectRoomInfos} from "../../rooms/rooms_selectors";
import DataTable from "../../shared/data_table/data_table";
import OpOverlapIcon from "../../shared/icons/op_overlap_icon";
import {disableSortColumns, getShortLabels} from "../helpers";
import useStyles from "./conflict_dialog.styles";
import OpStatus from "./op_status";
import PatientDetails from "./patient_details";
import SurgeryName from "./surgery_name";

/**
 * render ConflictDialog component
 *
 * @param {object} props
 * @param {function} props.onClose
 * @param {function} props.onRowClick
 * @param {function} props.onOpenEditLayer
 * @param {PlanBox[]} props.data
 * @return {React.ReactElement}
 */
const ConflictDialog = ({onClose, onRowClick, onOpenEditLayer, data}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const {isGranted} = useSecurity();
    const {formatFromISO} = useContext(DateContext);

    const {
        TEXT_PUNCTUATION: {HYPHEN}
    } = config;

    // Redux
    const roomInfos = useSelector(selectRoomInfos);
    const changes = useSelector(selectOpBacklogResourceHistories);
    const {emergencyThreshold} = useSelector(selectFeSettings);

    const {STATUS_ON_HOLD} = RESOURCE_HISTORY;

    const dataFormatted = data.map((op, index) => {
        // room
        const roomInfo = roomInfos.find((room) => room.id === op._location.reference);

        // start/end date
        const startDate = op._internalTimestamps?.duraRoomLockPre?.dtStart;
        const endDate = op._internalTimestamps?.duraRoomLockPost?.dtEnd;

        // changes of status to on-hold (to show the number of changes)
        const resourcePathOnHold = `${STATUS_ON_HOLD.resource}-${STATUS_ON_HOLD.path}`;
        const statusCancelledChanges = changes[resourcePathOnHold]?.find((opChanges) => opChanges.id === op.id);

        const status = opDisplayStatus(op._status, op._statusServiceRequest, op._isCutOver, op._error.code);
        const count = status === STATUS_KEY.ON_HOLD && statusCancelledChanges?.changes ? `(${statusCancelledChanges.changes.length})` : "";

        const surgery = op._interventions.main?.[0].display;

        const tableProperties = {
            id: op.id,
            surgery,
            key: `${op.id}+${index}`,
            status: status !== STATUS_KEY.INCONSISTENT ? t(`Status.${status}`) + " " + count : "",
            conflicts: "true",
            room: roomInfo?.name || HYPHEN,
            planned: startDate,
            start: startDate ? formatFromISO(startDate, DATE_FORMATS.TIME) : HYPHEN,
            end: endDate ? formatFromISO(endDate, DATE_FORMATS.TIME) : HYPHEN
        };
        const displayStartDate = startDate ? formatFromISO(startDate, DATE_FORMATS.DATE_MEDIUM) : HYPHEN;
        const isCutOver = op._isCutOver;
        return {
            ...tableProperties,
            display: {
                ...tableProperties,
                patient: <PatientDetails id={op._patient?.id} />,
                surgery: <SurgeryName error={op._error} isCutOver={isCutOver} surgery={surgery} />,
                registered: op._authoredOn && formatFromISO(op._authoredOn, DATE_FORMATS.DATE_MEDIUM),
                status: <OpStatus isCutOver={isCutOver} isLocked={op._isLocked} status={tableProperties.status} />,
                conflicts: (
                    <Box className={classes.overlapIconWrapper}>
                        <OpOverlapIcon />
                    </Box>
                ),
                planned: displayStartDate,
                actions: (
                    <div>
                        <Button
                            className={classes.buttonManage}
                            disabled={!(op._isEditable && isGranted(PERMISSION.MODIFY_PLAN))}
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                onOpenEditLayer(op.id, op._interventions.main?.[0].code, op._healthcareService.reference);
                            }}
                        >
                            {t("OpBacklogView.manage")}
                        </Button>
                    </div>
                )
            },
            tooltip: {
                ...tableProperties,
                planned: displayStartDate
            },
            // tooltip: {},
            isEmergency: op._priority >= emergencyThreshold
        };
    });
    return (
        <>
            <DialogTitle className={classes.title} variant="h1">
                {t("ConflictDialog.title")}
                <Close className={classes.icon} onClick={() => onClose()} />
            </DialogTitle>
            <DialogContent className={classes.content}>
                <DataTable
                    data={dataFormatted}
                    disableSortColumns={disableSortColumns}
                    hasMaxHeight={false}
                    isPaginationVisible={false}
                    labels={getShortLabels()}
                    tableLeftRightPadding={100}
                    tableTopBottomPadding={100}
                    onRowClick={onRowClick}
                />
            </DialogContent>
        </>
    );
};
ConflictDialog.propTypes = {
    onClose: func.isRequired,
    onRowClick: func.isRequired,
    onOpenEditLayer: func.isRequired,
    data: array.isRequired
};
export default ConflictDialog;
