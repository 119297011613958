// @ts-check
import {CheckCircle, Info, Warning} from "@mui/icons-material";
import {Box, Button, IconButton} from "@mui/material";
import {array, bool, func, number} from "prop-types";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {RESOURCE_HISTORY} from "../../../../config/api_config";
import config from "../../../../config/config.json";
import {STATUS_KEY} from "../../../../config/op_status";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {selectOpBacklogFilterExpand, selectOpBacklogResourceHistories} from "../../../pages/op_backlog/op_backlog_selectors";
import {checkOpOverlap} from "../../../utils/check_op_overlap";
import getDiscipline from "../../../utils/get_discipline";
import {getParticipantCategories} from "../../../utils/get_participant_category";
import {opDisplayStatus} from "../../../utils/op_status";
import {PERMISSION, useSecurity} from "../../../utils/security";
import {selectFeSettings} from "../../fe_settings/fe_settings_selectors";
import {selectPatientInfos} from "../../private_data/private_data_selectors";
import buildFullName from "../../private_data/utils/build_fullname";
import getPractitionerIdsFromScheduleOpByCategory from "../../private_data/utils/get_practitioner_ids_from_schedule_ops_by_category";
import {selectRoomInfos} from "../../rooms/rooms_selectors";
import DataTable from "../../shared/data_table/data_table";
import DetailDialog from "../../shared/detail_dialog/detail_dialog";
import OpOverlapIcon from "../../shared/icons/op_overlap_icon";
import {chechNumberOfOverlappedOps, disableSortColumns, getLabels} from "../helpers";
import useStyles from "../op_backlog_view.styles";
import ConflictDialog from "./conflict_dialog";
import LabelWithExpand from "./label_with_expand";
import OpStatus from "./op_status";
import Participants from "./participants";
import PatientDetails from "./patient_details";
import SurgeryName from "./surgery_name";

/**
 * The callback function called on click of the manage button.
 *
 * @callback OnOpenEditLayer
 * @param {String} opId
 * @param {String} procedureCode the code of the surgery
 * @param {String} hcServiceId
 */
/**
 * Renders the op backlog table
 *
 * @param {object} props The props
 * @param {Array<PlanBox>} props.tableData The list opData to be displayed in the table
 * @param {Array<PlanBox>} [props.alldata = []] The list opData used to check conflicts
 * @param {OnOpenEditLayer} props.onOpenEditLayer The function to open the edit layer
 * @param {Number} props.selectedTab
 * @param {Function} props.onRowClick
 * @param {Boolean} props.isBlockscreenVisible
 * @param {MouseEventHandler} props.onShowConflict
 * @param {boolean} [props.isConflictFilterSet]
 * @param {Boolean} [props.isErrorTable=false]
 * @return {React.ReactElement}
 */
export const OpBacklogTable = ({
    tableData,
    alldata = [],
    onOpenEditLayer,
    selectedTab,
    onRowClick,
    isBlockscreenVisible,
    onShowConflict,
    isConflictFilterSet,
    isErrorTable
}) => {
    const {t} = useTranslation();
    const {classes} = useStyles();
    const {formatFromISO} = useContext(DateContext);
    const {isGranted} = useSecurity();

    // States
    const [page, setPage] = useState(0);
    const [openConflicts, setOpenConflicts] = useState(false);
    const [conflictOps, setConflictOps] = useState([]);

    // Redux
    const roomInfos = useSelector(selectRoomInfos);
    const {emergencyThreshold, participantCategoriesForHealthcareService} = useSelector(selectFeSettings);
    const isFilterExpanded = useSelector(selectOpBacklogFilterExpand);
    const changes = useSelector(selectOpBacklogResourceHistories);
    const patientInfos = useSelector(selectPatientInfos);

    /**
     * @description Set the table page back to 0 whenever a filter is applied
     */
    useEffect(() => {
        setPage(0);
    }, [tableData.length]);

    const {
        TEXT_PUNCTUATION: {HYPHEN}
    } = config;

    /** @type {number} The padding to dynamically define the height of the data table */
    const tableTopBottomPadding = isFilterExpanded ? 500 : 400;

    const labels = getLabels();

    // add expand/fold icon to the surgeon and anesthesia columns
    const surgeonLabel = labels.find((label) => label.id === "surgeons");
    surgeonLabel.label = <LabelWithExpand columnId="surgeons" />;

    const anesthetistLabel = labels.find((label) => label.id === "anesthetist");
    anesthetistLabel.label = <LabelWithExpand columnId="anesthetist" />;

    const {STATUS_ON_HOLD} = RESOURCE_HISTORY;

    const handleClickConflicts = (e, overlappedOps) => {
        e.stopPropagation();
        setOpenConflicts(true);
        setConflictOps(overlappedOps);
    };

    const handleCloseConflicts = () => {
        setOpenConflicts(false);
        setConflictOps([]);
    };
    // Calculate how long this function takes to run
    console.time("start useMemo -> tableData");
    const dataFormatted = useMemo(() => {
        console.log("useMemo -> tableData", tableData);
        return tableData.map((op, index) => {
            // medical clearance
            const hasMedicalClearance = Boolean(
                op._medicalApproval.find((approval) => approval.type === "next_medClearance")?.practitionerId
            );

            // room
            const roomInfo = roomInfos.find((room) => room.id === op._location.reference);

            // start/end date
            const startDate = op._internalTimestamps?.duraRoomLockPre?.dtStart;
            const endDate = op._internalTimestamps?.duraRoomLockPost?.dtEnd;

            const hcServiceId = getDiscipline(op);
            // participants
            const {surgeons, anesthesias, anesthesiaNurses, surgeryNurses} = getPractitionerIdsFromScheduleOpByCategory(
                op._team,
                getParticipantCategories({participantCategoriesForHealthcareService, hcServiceId})
            );

            const cardiacPerfusionistIds = Object.values(op._team?.cardiacPerfusionist || {}).filter(Boolean);

            // changes of status to on-hold (to show the number of changes)
            const resourcePathOnHold = `${STATUS_ON_HOLD.resource}-${STATUS_ON_HOLD.path}`;
            const statusCancelledChanges = changes[resourcePathOnHold]?.find((opChanges) => opChanges.id === op.id);

            const status = opDisplayStatus(op._status, op._statusServiceRequest, op._isCutOver, op._error.code);
            const count =
                status === STATUS_KEY.ON_HOLD && statusCancelledChanges?.changes ? `(${statusCancelledChanges.changes.length})` : "";

            const surgery = op._interventions.main?.[0].display;

            const {hasOverlap, overlappedOps} = checkOpOverlap(op, alldata);

            const patientId = op._patient?.id;
            const name = buildFullName(patientInfos[patientId], patientId);

            const tableProperties = {
                surgery,
                id: op.id,
                key: `${op.id}+${index}`,
                patient: name,
                registered: op._authoredOn && formatFromISO(op._authoredOn, DATE_FORMATS.ISO_DATE),
                status: status !== STATUS_KEY.INCONSISTENT ? t(`Status.${status}`) + " " + count : "",
                conflicts: isErrorTable ? false : hasOverlap.toString(), // not show conflicts for error table
                medicalClearance: hasMedicalClearance,
                room: roomInfo?.name || HYPHEN,
                planned: startDate,
                start: startDate,
                end: endDate ? formatFromISO(endDate, DATE_FORMATS.TIME) : HYPHEN
            };
            const displayStartDate = startDate ? formatFromISO(startDate, DATE_FORMATS.DATE_MEDIUM) : HYPHEN;
            const isCutOver = op._isCutOver;
            return {
                ...tableProperties,
                display: {
                    ...tableProperties,
                    start: startDate ? formatFromISO(startDate, DATE_FORMATS.TIME) : HYPHEN,
                    patient: <PatientDetails id={op._patient?.id} />,
                    surgery: <SurgeryName error={op._error} isCutOver={isCutOver} surgery={surgery} />,
                    registered: op._authoredOn && formatFromISO(op._authoredOn, DATE_FORMATS.DATE_MEDIUM),
                    status: <OpStatus isCutOver={isCutOver} isLocked={op._isLocked} status={tableProperties.status} />,
                    conflicts: !isErrorTable && hasOverlap && (
                        <IconButton className={classes.overlapIconButton} onClick={(e) => handleClickConflicts(e, overlappedOps)}>
                            <OpOverlapIcon />
                        </IconButton>
                    ),
                    planned: displayStartDate,
                    medicalClearance: hasMedicalClearance ? <CheckCircle className={classes.checkIcon} fontSize="small" /> : HYPHEN,
                    surgeons: surgeons && <Participants columnId="surgeons" ids={surgeons} surgeonPresenting={op._surgeonPresenting} />,
                    anesthetist: anesthesias && <Participants columnId="anesthetist" ids={anesthesias} />,
                    anesthesiaNurses: anesthesiaNurses && <Participants columnId="anesthesiaNurses" ids={anesthesiaNurses} />,
                    opNurse: surgeryNurses && <Participants columnId="opNurse" ids={surgeryNurses} />,
                    cardiacPerfusionist: cardiacPerfusionistIds && (
                        <Participants columnId="cardiacPerfusionist" ids={cardiacPerfusionistIds} />
                    ),
                    actions: (
                        <div>
                            <Button
                                className={classes.buttonManage}
                                disabled={!(op._isEditable && isGranted(PERMISSION.MODIFY_PLAN))}
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onOpenEditLayer(op.id, op._interventions.main?.[0].code, op._healthcareService.reference);
                                }}
                            >
                                {t("OpBacklogView.manage")}
                            </Button>
                        </div>
                    )
                },
                tooltip: {
                    ...tableProperties,
                    planned: displayStartDate
                },
                // tooltip: {},
                isEmergency: op._priority >= emergencyThreshold
            };
        });
    }, [tableData, changes]);
    console.timeEnd("end useMemo -> tableData");
    const conflictCount = chechNumberOfOverlappedOps(tableData, alldata);

    return (
        <div data-testid="op-backlog-table">
            {!isErrorTable && (
                <Box sx={{display: "flex"}}>
                    {<Trans components={{bold: <strong />}} i18nKey="OpBacklogView.result" values={{count: dataFormatted.length}} />}
                    {!conflictCount && (
                        <div className={classes.resultInfo}>
                            <Info className={classes.icon} />
                            <span>{t("OpBacklogView.noConflict")}</span>
                        </div>
                    )}
                    {conflictCount > 0 && (
                        <div className={classes.resultWarning}>
                            <Warning className={classes.icon} color="error" />
                            <span>{t("OpBacklogView.conflictWarning", {count: conflictCount})}</span>
                            {!isConflictFilterSet && (
                                <Button className={classes.showConflictButton} variant="text" onClick={onShowConflict}>
                                    {t("OpBacklogView.showConflicts", {count: conflictCount})}
                                </Button>
                            )}
                        </div>
                    )}
                </Box>
            )}
            <DataTable
                data={dataFormatted}
                defaultOrder={selectedTab !== 2 ? "asc" : "desc"}
                defaultSorted={selectedTab !== 2 ? "planned" : "status"}
                disableSortColumns={disableSortColumns}
                hasMaxHeight={false}
                isErrorTable={isErrorTable}
                labels={labels}
                page={page}
                tableLeftRightPadding={64 + 64 + 30 + 30} // left menu: 64, right actionbar: 64, left/right padding within the page
                tableTopBottomPadding={tableTopBottomPadding}
                onRowClick={onRowClick}
                onSetPage={setPage}
            />
            {openConflicts && (
                <DetailDialog
                    isBlockscreenVisible={isBlockscreenVisible}
                    open={openConflicts}
                    styles={{root: classes.detailDialogRoot}}
                    onClose={handleCloseConflicts}
                >
                    <ConflictDialog
                        data={conflictOps}
                        onClose={handleCloseConflicts}
                        onOpenEditLayer={onOpenEditLayer}
                        onRowClick={onRowClick}
                    />
                </DetailDialog>
            )}
        </div>
    );
};

OpBacklogTable.propTypes = {
    tableData: array.isRequired,
    alldata: array.isRequired,
    onOpenEditLayer: func.isRequired,
    selectedTab: number,
    onRowClick: func.isRequired,
    onShowConflict: func.isRequired,
    isErrorTable: bool,
    isConflictFilterSet: bool,
    isBlockscreenVisible: bool.isRequired
};
