// @ts-check
import {ArrowDropDown} from "@mui/icons-material";
import {Button} from "@mui/material";
import {t} from "i18next";
import {arrayOf, object, string} from "prop-types";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {selectOpBacklog} from "../../../pages/op_backlog/op_backlog_selectors";
import {formatSurgeon} from "../../../utils/format_op_team";
import {selectStandardNamesArray} from "../../private_data/private_data_selectors";
import useStyles from "../op_backlog_view.styles";

/**
 * Participants component
 * @param {Object} props
 * @param {Array<String>} props.ids
 * @param {String} props.columnId
 * @param {SurgeonPresenting} [props.surgeonPresenting]
 * @return {React.ReactElement}
 */
const Participants = ({ids, columnId, surgeonPresenting}) => {
    const {classes} = useStyles();
    const {reference, isAttending} = surgeonPresenting || {};
    const allIds = surgeonPresenting && reference && !ids.includes(reference) ? ids.concat(reference) : ids;

    // Redux
    const standardNamesArray = useSelector(selectStandardNamesArray({ids: allIds, type: "practitioner"}));
    const {isColumnExpanded} = useSelector(selectOpBacklog);

    const [isOpened, setIsOpened] = useState(!!isColumnExpanded[columnId]);

    useEffect(() => {
        setIsOpened(!!isColumnExpanded[columnId]);
    }, [isColumnExpanded[columnId]]);

    const handleClick = (e) => {
        e.stopPropagation();
        setIsOpened(!isOpened);
    };
    const firstPractitionerName = standardNamesArray.length
        ? formatSurgeon(standardNamesArray[0]?.name, isAttending, standardNamesArray[0]?.id === reference)
        : undefined;
    return (
        <div>
            {isOpened ? (
                standardNamesArray.map((participant, index) => {
                    const formattedName = formatSurgeon(participant.name, isAttending, participant.id === reference);
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className={classes.participantName} key={participant.id + index} title={formattedName}>
                            {formattedName}
                        </div>
                    );
                })
            ) : (
                <div className={classes.participantName} title={firstPractitionerName}>
                    {firstPractitionerName}
                </div>
            )}
            {isOpened && allIds.length > 1 && (
                <Button className={classes.toggleParticipants} onClick={handleClick}>
                    {t("OpBacklogView.less")}
                </Button>
            )}
            {!isOpened && allIds.length > 1 && (
                <Button className={classes.toggleParticipants} onClick={handleClick}>
                    {"+" + (allIds.length - 1)} <ArrowDropDown className={classes.arrowDown} />
                </Button>
            )}
        </div>
    );
};
Participants.propTypes = {
    ids: arrayOf(string).isRequired,
    columnId: string.isRequired,
    surgeonPresenting: object
};
export default Participants;
