import NotificationsIcon from "@mui/icons-material/Notifications";
import {Badge} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import useStyles from "./notifications_badge.styles";

const NotificationsBadge = ({active, classes, color, count, fontSize, max, cx}) => {
    const mergedClasses = {...useStyles(), ...classes};

    return (
        <Badge badgeContent={count} classes={{badge: mergedClasses.badge}} color={color} max={max} overlap="circular">
            <NotificationsIcon
                className={cx(mergedClasses.icon, {
                    [mergedClasses.active]: active,
                    [mergedClasses.inactive]: !active
                })}
                fontSize={fontSize}
            />
        </Badge>
    );
};

NotificationsBadge.propTypes = {
    active: PropTypes.bool,
    classes: PropTypes.shape({
        icon: PropTypes.string
    }),
    color: PropTypes.string,
    count: PropTypes.number,
    fontSize: PropTypes.string,
    max: PropTypes.number,
    cx: PropTypes.func.isRequired
};

NotificationsBadge.defaultProps = {
    classes: {},
    color: "error",
    fontsize: "small",
    max: 9
};

export default NotificationsBadge;
