// @ts-check
import {makeStyles} from "tss-react/mui";

export default makeStyles()((/** @type AllCustomTheme */ theme) => ({
    opBacklogRoot: {
        height: "100%"
    },
    titleRow: {
        height: "100px",
        width: "100%",
        fontSize: "23px",
        lineHeight: "100px",
        padding: "0 30px",
        fontWeight: theme.typography.fontWeightMedium,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    title: {
        display: "flex"
    },
    dateRange: {
        "marginLeft": theme.spacing("l"),
        "& .MuiFormControl-root": {
            verticalAlign: "unset"
        }
    },
    to: {
        top: "unset"
    },
    OpBacklogView: {
        height: "38px"
    },

    tableArea: {
        padding: "0 30px 30px",
        height: "calc(100% - 130px)",
        overflowY: "hidden"
    },
    tabPanel: {
        height: "calc(100% - 4px)",
        padding: "0",
        overflow: "auto"
    },
    tabs: {
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
    tab: {
        textTransform: "capitalize",
        fontWeight: theme.typography.fontWeightMedium,
        minWidth: "140px",
        fontSize: "14px"
    },
    tabCount: {
        color: theme.palette.grey[500],
        fontWeight: theme.typography.fontWeightRegular
    },
    buttonManage: {
        textTransform: "capitalize",
        color: theme.palette.primary.main
    },
    checkIcon: {
        color: theme.palette.success.main,
        width: "18px",
        height: "18px",
        padding: "1px"
    },
    overlapIconButton: {
        padding: "0",
        marginLeft: "1rem"
    },
    toggleParticipants: {
        color: theme.palette.primary.main,
        textTransform: "capitalize",
        padding: "0",
        fontSize: "14px",
        justifyContent: "flex-start"
    },
    arrowDown: {
        fontSize: "14px"
    },
    expandFoldIcon: {
        "padding": "6px",
        "cursor": "pointer",
        "pointerEvents": "auto",
        "& path": {
            fill: theme.palette.primary.main
        }
    },
    formWrapper: {
        padding: "40px 0"
    },
    formRow: {
        display: "flex"
    },
    autocomplete: {
        width: "200px",
        marginRight: "20px !important",
        marginBottom: "20px !important",
        flexShrink: 0
    },
    participantName: {
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    inputDate: {
        paddingTop: "4px",
        width: "180px"
    },
    ml1: {
        marginLeft: theme.spacing("m")
    },
    showMore: {
        color: theme.palette.primary.main,
        textTransform: "capitalize",
        padding: "0",
        justifyContent: "flex-start",
        fontSize: "14px",
        height: "2rem",
        marginTop: "1rem",
        lineHeight: theme.spacing("m")
    },
    ageFilter: {
        width: "100px",
        flexShrink: 0
    },
    hyphen: {
        marginTop: "1.5rem",
        marginLeft: "0.5rem"
    },
    detailDialogRoot: {
        maxWidth: "1100px",
        maxHeight: "fit-content",
        borderRadius: 0
    },
    error: {
        display: "flex",
        alignItems: "flex-end"
    },
    errorIcon: {
        color: theme.palette.error.main,
        width: "24px",
        height: "24px",
        marginRight: "0.5rem"
    },
    text: {
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    inconsistent: {
        color: theme.palette.error.main,
        marginBottom: theme.spacing("m"),
        background: theme.palette.error.light,
        width: "100%",
        display: "flex",
        alignItems: "center",
        height: "39px",
        padding: "0 0.5rem",
        cursor: "pointer",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    bold: {
        fontWeight: theme.typography.fontWeightBold,
        margin: "0 0.3rem"
    },
    patient: {
        display: "flex",
        flexDirection: "column"
    },
    opStatus: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end"
    },
    lockIcon: {
        width: "18px",
        height: "18px",
        background: "#f7f7f7",
        borderRadius: "50%",
        color: theme.palette.text.secondary,
        padding: "1px"
    },
    resultInfo: {
        marginLeft: "1rem",
        color: theme.palette.text.primary,
        display: "flex",
        alignItems: "center"
    },
    resultWarning: {
        marginLeft: "1rem",
        color: theme.palette.error.main,
        display: "flex",
        alignItems: "center"
    },
    icon: {
        marginRight: "0.5rem",
        width: "20px",
        height: "20px"
    },
    showConflictButton: {
        marginLeft: "0.5rem",
        padding: 0,
        textTransform: "none",
        fontSize: "0.875rem"
    }
}));
