// @ts-check
import {t} from "i18next";

import {APPOINTMENT_STATUS, STATUS_KEY} from "../../../config/op_status";
import calculateAge from "../../utils/calculate_age";
import {checkOpOverlap} from "../../utils/check_op_overlap";
import getDiscipline from "../../utils/get_discipline";
import {getParticipantCategories} from "../../utils/get_participant_category";
import {opDisplayStatus} from "../../utils/op_status";
import getPractitionerIdsFromScheduleOpByCategory from "../private_data/utils/get_practitioner_ids_from_schedule_ops_by_category";
/**
 * Check if the given date is valid
 * @param {Date|null} date
 * @return {Boolean}
 */
export const isValidDate = (date) => !isNaN(date.getTime());

/**
 * Convert a list of ids into real names using information from the privateData slice
 *
 * @param {Array<string>} ids The list of ids to transform into real names
 * @param {IdToNameCollection} allNamesObject The object containing the ids mapped to real names
 * @return {Array<string>} The list of real names
 */
export const getNames = (ids, allNamesObject) => ids.map((id) => allNamesObject[id]);

/**
 * @typedef {Object} OpStatusCount
 * @property {number} all
 * @property {number} planned
 * @property {number} onHold
 * @property {number} cantPlan
 * @property {number} started
 * @property {number} done
 * @property {number} permanentlyRemoved
 */

/**
 * count op backlog for each status
 *
 * @param {Array<PlanBox>} data
 * @return {OpStatusCount}
 */
export const countOpBacklogPerStatus = (data) => {
    /** @type {OpStatusCount} */
    // @ts-ignore
    const initialValues = Object.values(STATUS_KEY).reduce((acc, curr) => {
        acc[curr] = 0;
        return acc;
    }, {});
    return data.reduce((acc, curr) => {
        const status = opDisplayStatus(curr._status, curr._statusServiceRequest, curr._isCutOver, curr._error.code);
        if (status) {
            acc[status] += 1;
        }
        if (status !== STATUS_KEY.INCONSISTENT && status !== null) {
            acc.all += 1;
        }
        return acc;
    }, initialValues);
};

/** @todo relative width is not perfectly working, to be improved #14375 */
const TABLE_WIDTH = {
    surgery: 8,
    id: 7,
    registered: 8,
    status: 8,
    conflicts: 8,
    medicalClearance: 5,
    room: 5,
    planned: 5,
    start: 5,
    end: 5,
    surgeons: 6,
    anesthetist: 6,
    anesthesiaNurses: 6,
    opNurse: 6,
    cardiacPerfusionist: 6,
    actions: 6
};

/**
 * A getter for the basic columns labels
 *
 * @return {Array<DataTableLabel>} The basic label(s)
 */
export const getLabels = () => [
    {id: "patient", label: t("OpBacklogView.patient"), width: "9rem", setTitle: true, isSticky: true},
    {id: "surgery", label: t("OpBacklogView.surgery"), width: `${TABLE_WIDTH.surgery}%`, setTitle: false},
    {id: "id", label: t("OpBacklogView.opId"), width: `${TABLE_WIDTH.id}%`, setTitle: true},
    {id: "registered", label: t("OpBacklogView.registeredOn"), width: `${TABLE_WIDTH.registered}%`, setTitle: true},
    {id: "status", label: t("OpBacklogView.status"), width: `${TABLE_WIDTH.status}%`, setTitle: false},
    {id: "conflicts", label: t("OpBacklogView.conflicts"), width: `${TABLE_WIDTH.conflicts}%`, setTitle: false},
    {id: "medicalClearance", label: t("OpBacklogView.medicalClearance"), width: `${TABLE_WIDTH.medicalClearance}%`, setTitle: false},
    {id: "room", label: t("OpBacklogView.room"), width: `${TABLE_WIDTH.room}%`, setTitle: true},
    {id: "planned", label: t("OpBacklogView.plannedOn"), width: `${TABLE_WIDTH.planned}%`, setTitle: true},
    {id: "start", label: t("OpBacklogView.start"), width: `${TABLE_WIDTH.start}%`, setTitle: false},
    {id: "end", label: t("OpBacklogView.end"), width: `${TABLE_WIDTH.end}%`, setTitle: false},
    {id: "surgeons", label: t("OpBacklogView.surgeons"), width: `${TABLE_WIDTH.surgeons}%`, setTitle: false},
    {id: "anesthetist", label: t("OpBacklogView.anesthetist"), width: `${TABLE_WIDTH.anesthetist}%`, setTitle: false},
    {id: "anesthesiaNurses", label: t("OpBacklogView.anesthesiaNurses"), width: `${TABLE_WIDTH.anesthesiaNurses}%`, setTitle: false},
    {id: "opNurse", label: t("OpBacklogView.opNurse"), width: `${TABLE_WIDTH.opNurse}%`, setTitle: false},
    {
        id: "cardiacPerfusionist",
        label: t("OpBacklogView.cardiacPerfusionist"),
        width: `${TABLE_WIDTH.cardiacPerfusionist}%`,
        setTitle: false
    },
    {id: "actions", label: t("OpBacklogView.actions"), width: `${TABLE_WIDTH.actions}%`, setTitle: false}
];

const CONFLIC_TABLE_WIDTH = {
    patient: "9rem",
    surgery: "12%",
    status: "12%",
    conflicts: "10%",
    room: "10%",
    planned: "10%",
    start: "10%",
    end: "10%",
    actions: "10%"
};
/**
 * A getter for the basic columns labels
 *
 * @return {Array<DataTableLabel>} The basic label(s)
 */
export const getShortLabels = () => [
    {id: "patient", label: t("OpBacklogView.patient"), width: CONFLIC_TABLE_WIDTH.patient, setTitle: true},
    {id: "surgery", label: t("OpBacklogView.surgery"), width: CONFLIC_TABLE_WIDTH.surgery, setTitle: false},
    {id: "status", label: t("OpBacklogView.status"), width: CONFLIC_TABLE_WIDTH.status, setTitle: false},
    {id: "conflicts", label: t("OpBacklogView.conflicts"), width: CONFLIC_TABLE_WIDTH.conflicts, setTitle: true},
    {id: "room", label: t("OpBacklogView.room"), width: CONFLIC_TABLE_WIDTH.room, setTitle: true},
    {id: "planned", label: t("OpBacklogView.plannedOn"), width: CONFLIC_TABLE_WIDTH.planned, setTitle: true},
    {id: "start", label: t("OpBacklogView.start"), width: CONFLIC_TABLE_WIDTH.start, setTitle: false},
    {id: "end", label: t("OpBacklogView.end"), width: CONFLIC_TABLE_WIDTH.end, setTitle: false},
    {id: "actions", label: t("OpBacklogView.actions"), width: CONFLIC_TABLE_WIDTH.actions, setTitle: false}
];

export const disableSortColumns = ["id", "surgery", "reason", "end", "surgeons", "anesthetist", "anesthesiaNurses", "opNurse", "actions"];

/**
 * filter op backlog list by all the filters other than dateRange
 * @param {object} params
 * @param {PlanBox[]} params.opBacklogList
 * @param {Array<{label: String, value: String}>} params.selectedSurgeryNames
 * @param {Array<{label: String, value: String}>} params.selectedOperatorNames
 * @param {String[]} params.selectedRooms
 * @param {String[]} params.selectedHealthcareServices
 * @param {String} params.selectedPatientName
 * @param {String} params.selectedPatientAgeFrom
 * @param {String} params.selectedPatientAgeTo
 * @param {Number} params.selectedPriority
 * @param {boolean} params.selectedConflict
 * @param {IdToNameCollection} params.allPatientNamesObject
 * @param {Object} params.allPatientBirthDateObject
 * @param {ParticipantCategoriesForHealthcareService[]} params.participantCategoriesForHealthcareService
 * @return {PlanBox[]}
 */
export const filterOpBacklog = ({
    opBacklogList,
    selectedSurgeryNames,
    selectedOperatorNames,
    selectedRooms,
    selectedHealthcareServices,
    selectedPatientName,
    selectedPatientAgeFrom,
    selectedPatientAgeTo,
    selectedPriority,
    selectedConflict,
    allPatientNamesObject,
    allPatientBirthDateObject,
    participantCategoriesForHealthcareService
}) =>
    opBacklogList.filter((op) => {
        let isFilterMatched = true;
        if (selectedSurgeryNames.length) {
            if (!selectedSurgeryNames.map((surgeryName) => surgeryName.value).includes(op._interventions.main[0]?.display)) {
                isFilterMatched = false;
            }
        }
        const hcServiceId = getDiscipline(op);

        if (selectedOperatorNames.length) {
            const {surgeons} = getPractitionerIdsFromScheduleOpByCategory(
                op._team,
                getParticipantCategories({participantCategoriesForHealthcareService, hcServiceId})
            );
            const surgeonIncludePresenting = op._surgeonPresenting?.reference
                ? [...(surgeons || []), op._surgeonPresenting.reference]
                : surgeons || [];
            const isOneOfTheSurgeonsSelected = surgeonIncludePresenting.some((id) =>
                selectedOperatorNames.map((operator) => operator.value).includes(id)
            );
            if (!surgeons || !isOneOfTheSurgeonsSelected) {
                isFilterMatched = false;
            }
        }
        if (selectedRooms.length) {
            if (!selectedRooms.includes(op._location.reference)) {
                isFilterMatched = false;
            }
        }
        if (selectedHealthcareServices.length) {
            if (!selectedHealthcareServices.includes(op._healthcareService.reference)) {
                isFilterMatched = false;
            }
        }

        if (selectedPatientName) {
            const patientName = allPatientNamesObject[op._patient.id] || "";
            if (!patientName.toLowerCase().includes(selectedPatientName?.toLowerCase())) {
                isFilterMatched = false;
            }
        }

        const age = allPatientBirthDateObject[op._patient.id] && calculateAge(allPatientBirthDateObject[op._patient.id]);
        const isAgeUnknown = age === null || typeof age === "undefined";
        const isFilterFromSet =
            selectedPatientAgeFrom !== null && typeof selectedPatientAgeFrom !== "undefined" && selectedPatientAgeFrom !== "";

        // The age will be filtered if ageFilter has a value
        if (isFilterFromSet && (isAgeUnknown || parseInt(selectedPatientAgeFrom, 10) > age)) {
            isFilterMatched = false;
        }
        const isFilterToSet = selectedPatientAgeTo !== null && typeof selectedPatientAgeTo !== "undefined" && selectedPatientAgeTo !== "";
        if (isFilterToSet && (isAgeUnknown || parseInt(selectedPatientAgeTo, 10) < age)) {
            isFilterMatched = false;
        }
        if (selectedPriority) {
            if (!op._priority || (op._priority && op._priority !== selectedPriority)) {
                isFilterMatched = false;
            }
        }

        if (selectedConflict) {
            if (!checkOpOverlap(op, opBacklogList)?.hasOverlap) {
                isFilterMatched = false;
            }
        }
        return isFilterMatched;
    });

/**
 * Prefilter op backlog list. These ops are not included in the number of each tabs
 * if the start or end time of an appointment is not set, the filter will be ignored
 *
 * @param {object} params
 * @param {PlanBox[]} params.opBacklogList
 * @param {Date} params.dateStart
 * @param {Date} params.dateEnd
 * @param {Object} params.dateFunctions
 * @param {Function} params.dateFunctions.fromJSDate
 * @param {Function} params.dateFunctions.startOf
 * @param {Function} params.dateFunctions.fromISO
 * @param {Function} params.dateFunctions.endOf
 * @return {PlanBox[]}
 */
export const prefilter = ({opBacklogList, dateStart, dateEnd, dateFunctions: {fromJSDate, startOf, fromISO, endOf}}) =>
    opBacklogList.filter((op) => {
        let isFilterMatched = true;
        // exclude if the status is waitlist, and error is not set (code < 400 or code is null)
        if (op._status === APPOINTMENT_STATUS.WAITLIST && (op._error.code === null || op._error.code < 400)) {
            return false;
        }

        const {duraRoomLockPre, duraRoomLockPost} = op._internalTimestamps;
        if (dateStart) {
            if (duraRoomLockPost?.dtEnd && fromISO(duraRoomLockPost.dtEnd) < startOf(fromJSDate(dateStart), "day")) {
                isFilterMatched = false;
            }
        }
        if (dateEnd) {
            if (duraRoomLockPre?.dtStart && fromISO(duraRoomLockPre.dtStart) > endOf(fromJSDate(dateEnd), "day")) {
                isFilterMatched = false;
            }
        }

        return isFilterMatched;
    });

/**
 * Translates the reason(s) of the last change
 *
 * @param {string} reasonForChange A comma separated list of reasons. @example "orUnavailable,newEmergency,surgeryCompleted"
 * @return {string} The translated string. @example "OP-Saal nicht verfügbar, Neuer Notfall, Eingriff abgeschlossen"

 */
export const translateReasonForChange = (reasonForChange) => {
    const reasonList = reasonForChange.split(",");
    const reasonsTranslated = reasonList.map((reason) => t(`ReasonForChange.${reason}`));
    return reasonsTranslated.join(", ");
};

export const RHF_BACKLOG_NAMES = {
    surgeryNameFilter: "surgeryNameFilter",
    operatorNameFilter: "operatorNameFilter",
    dateRange_start: "dateRange_start",
    dateRange_end: "dateRange_end",
    roomFilter: "roomFilter",
    healthcareServiceFilter: "healthcareServiceFilter",
    patientNameFilter: "patientNameFilter",
    patientAgeFrom: "patientAgeFrom",
    patientAgeTo: "patientAgeTo",
    priorityFilter: "priorityFilter",
    conflictFilter: "conflictFilter"
};

export const NUMBER_ITEMS = [RHF_BACKLOG_NAMES.priorityFilter, RHF_BACKLOG_NAMES.patientAgeFrom, RHF_BACKLOG_NAMES.patientAgeTo];

/**
 * count the number of ops that have overlapping
 *
 * @param {PlanBox[]} ops
 * @param {PlanBox[]} allOps
 * @return {number}
 */
export const chechNumberOfOverlappedOps = (ops, allOps) => {
    let count = 0;
    for (let i = 0; i < ops.length; i++) {
        if (checkOpOverlap(ops[i], allOps)?.hasOverlap) {
            count = count + 1;
        }
    }
    return count;
};
