// @ts-check
import {yupResolver} from "@hookform/resolvers/yup";
import {Warning} from "@mui/icons-material";
import {TabContext, TabPanel} from "@mui/lab";
import {Tab, Tabs} from "@mui/material";
import {bool, func} from "prop-types";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import config from "../../../config/config.json";
import {EVENTS} from "../../../config/event_config";
import {CANT_PLAN_TAB, EXCLUDE_TAB, STATUS_KEY} from "../../../config/op_status";
import {DATE_FORMATS, DateContext} from "../../contexts/dates";
import useLocalStorageSetter from "../../hooks/use_local_storage_setter";
import {loadInfoAction} from "../../pages/day_view/day_view_actions";
import {selectOpBacklogList} from "../../pages/op_backlog/op_backlog_selectors";
import {selectStatus} from "../../pages/op_management/op_management_selectors";
import {selectCurrentOrganizationId, selectCurrentUserEmail} from "../../redux/app_selectors";
import {getLocalStorageItem} from "../../utils/local_storage";
import logger from "../../utils/logger_pino";
import {opDisplayStatus} from "../../utils/op_status";
import {selectFeSettings} from "../fe_settings/fe_settings_selectors";
import OpEditLayer from "../op_edit_layer/op_edit_layer";
import {clearEditOpAction, fetchOptionsAction, loadEditOpAction} from "../op_edit_layer/op_edit_layer_actions";
import {selectEditOpData} from "../op_edit_layer/op_edit_layer_selectors";
import {selectAllFullNamesObject, selectAllPatientBirthDates} from "../private_data/private_data_selectors";
import {loadRoomsAction} from "../rooms/rooms_actions";
import {DateRangeNew} from "../shared/date_range_new/date_range_new";
import DetailDialog from "../shared/detail_dialog/detail_dialog";
import {BacklogFilterSchema} from "./components/backlog_filter_schema";
import {FiltersFormBacklog} from "./components/filters_form_backlog";
import InfoTicker from "./components/info_ticker";
import {OpBacklogTable} from "./components/op_backlog_table";
import Publish from "./components/publish";
import RequestPlan from "./components/request_plan";
import RequestPlanDialog from "./components/request_plan_dialog";
import TabLabel from "./components/tab_label";
import {countOpBacklogPerStatus, filterOpBacklog, isValidDate, NUMBER_ITEMS, prefilter, RHF_BACKLOG_NAMES} from "./helpers";
import useStyles from "./op_backlog_view.styles";

const OP_BACKLOG = "OpBacklog";

/**
 * render OpBacklogView
 *
 * @param {object} props
 * @param {boolean} props.isBlockscreenVisible
 * @param {Function} props.onRowClick
 * @return {React.ReactElement}
 */
const OpBacklogView = ({isBlockscreenVisible, onRowClick}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {now, format, minusDT, fromJSDate, startOf, fromISO, endOf} = useContext(DateContext);

    // Redux
    const {infoLayer: infoParams, participantCategoriesForHealthcareService} = useSelector(selectFeSettings);
    const organizationId = useSelector(selectCurrentOrganizationId);
    const email = useSelector(selectCurrentUserEmail);
    const opBacklogList = useSelector(selectOpBacklogList);
    const allPatientNamesObject = useSelector(selectAllFullNamesObject({type: "patient"}));
    const allPatientBirthDateObject = useSelector(selectAllPatientBirthDates);
    const editOpData = useSelector(selectEditOpData);
    const status = useSelector(selectStatus);

    // States
    const [selectedTab, setSelectedTab] = useState(0);
    const [openEditLayer, setOpenEditLayer] = useState(false);
    const [showInconsistentTable, setShowInconsistentTable] = useState(false);

    const {MIN_BACKLOG_IN_MONTH} = config;

    // form
    const defaultFilterValues = {
        [RHF_BACKLOG_NAMES.surgeryNameFilter]: [],
        [RHF_BACKLOG_NAMES.operatorNameFilter]: [],
        dateRange_min: minusDT(startOf(now(), "day"), "month", MIN_BACKLOG_IN_MONTH).toJSDate(), // used for validation in BacklogFilterSchema
        [RHF_BACKLOG_NAMES.roomFilter]: [],
        [RHF_BACKLOG_NAMES.healthcareServiceFilter]: [],
        [RHF_BACKLOG_NAMES.patientNameFilter]: "",
        [RHF_BACKLOG_NAMES.patientAgeFrom]: "",
        [RHF_BACKLOG_NAMES.patientAgeTo]: "",
        [RHF_BACKLOG_NAMES.priorityFilter]: "",
        [RHF_BACKLOG_NAMES.conflictFilter]: false
    };
    const defaultValues = {
        ...defaultFilterValues,
        [RHF_BACKLOG_NAMES.dateRange_start]: startOf(now(), "day").toJSDate(),
        [RHF_BACKLOG_NAMES.dateRange_end]: null
    };

    let lastValidDateStart = new Date();
    let lastValidDateEnd = null;

    const {
        control,
        // reset,
        getValues,
        watch,
        reset,
        formState: {errors, dirtyFields},
        setValue
        // @ts-ignore - jsdoc to be improved
    } = useForm({defaultValues, resolver: yupResolver(BacklogFilterSchema), mode: "onChange"}); // watch

    const selectedSurgeryNames = watch(RHF_BACKLOG_NAMES.surgeryNameFilter);
    const selectedOperatorNames = watch(RHF_BACKLOG_NAMES.operatorNameFilter);
    /** @type Date */
    // @ts-ignore jsdoc to be improved
    const dateStart = watch(RHF_BACKLOG_NAMES.dateRange_start);
    /** @type Date */
    // @ts-ignore jsdoc to be improved
    const dateEnd = watch(RHF_BACKLOG_NAMES.dateRange_end);
    const selectedRooms = watch(RHF_BACKLOG_NAMES.roomFilter);
    const selectedHealthcareServices = watch(RHF_BACKLOG_NAMES.healthcareServiceFilter);
    const selectedPatientName = watch(RHF_BACKLOG_NAMES.patientNameFilter);
    const selectedPatientAgeFrom = watch(RHF_BACKLOG_NAMES.patientAgeFrom);
    const selectedPatientAgeTo = watch(RHF_BACKLOG_NAMES.patientAgeTo);
    const selectedPriority = watch(RHF_BACKLOG_NAMES.priorityFilter);
    const selectedConflict = watch(RHF_BACKLOG_NAMES.conflictFilter);

    const handleReset = () => {
        // Reset only filterValues (except dateRange_start and dateRange_end)
        // @ts-ignore - value has a type error, jsdoc to be improved
        Object.entries(defaultFilterValues).forEach(([key, value]) => setValue(key, value, {shouldDirty: false}));
        reset({}, {keepValues: true});
    };
    const getValueFromLocalstorage = (name) => {
        // Get filters from the localStorage except dateRange
        const item = getLocalStorageItem(OP_BACKLOG, name);
        if (item && item !== "null") {
            // Exclude date filters to set from the localStorage
            if (![RHF_BACKLOG_NAMES.dateRange_end, RHF_BACKLOG_NAMES.dateRange_start].includes(name) && item !== "Invalid Date") {
                if (NUMBER_ITEMS.includes(name)) {
                    // @ts-ignore
                    setValue(name, Number(item), {shouldDirty: true});
                } else {
                    setValue(name, item, {shouldDirty: true});
                }
            }
        }
    };
    // Get filters from the localStorage
    useEffect(() => {
        Object.keys(RHF_BACKLOG_NAMES).forEach((name) => getValueFromLocalstorage(name));
    }, []);

    useEffect(() => {
        // if block screen is visible, force to close the edit layer
        if (isBlockscreenVisible) {
            dispatch(clearEditOpAction());
            setOpenEditLayer(false);
        }
    }, [isBlockscreenVisible]);

    // Set filters into localStorage
    useLocalStorageSetter(OP_BACKLOG, RHF_BACKLOG_NAMES.surgeryNameFilter, selectedSurgeryNames);
    useLocalStorageSetter(OP_BACKLOG, RHF_BACKLOG_NAMES.operatorNameFilter, selectedOperatorNames);
    useLocalStorageSetter(OP_BACKLOG, RHF_BACKLOG_NAMES.roomFilter, selectedRooms);
    useLocalStorageSetter(OP_BACKLOG, RHF_BACKLOG_NAMES.healthcareServiceFilter, selectedHealthcareServices);
    useLocalStorageSetter(OP_BACKLOG, RHF_BACKLOG_NAMES.patientNameFilter, selectedPatientName);
    useLocalStorageSetter(OP_BACKLOG, RHF_BACKLOG_NAMES.patientAgeFrom, selectedPatientAgeFrom);
    useLocalStorageSetter(OP_BACKLOG, RHF_BACKLOG_NAMES.patientAgeTo, selectedPatientAgeTo);
    useLocalStorageSetter(OP_BACKLOG, RHF_BACKLOG_NAMES.priorityFilter, selectedPriority);

    // Keep valid dateStart
    useEffect(() => {
        if (dateStart && isValidDate(dateStart) && fromJSDate(dateStart).isValid) {
            lastValidDateStart = dateStart;
        }
    }, [dateStart]);

    // Keep valid dateEnd
    useEffect(() => {
        if (dateEnd && isValidDate(dateEnd) && fromJSDate(dateEnd).isValid) {
            lastValidDateEnd = dateEnd;
        }
    }, [dateEnd]);

    useEffect(() => {
        if (organizationId) {
            dispatch(loadRoomsAction(organizationId));
            if (infoParams.info1 && infoParams.info2 && infoParams.info3) {
                dispatch(loadInfoAction({organizationId, date: format(now(), DATE_FORMATS.SYSTEM_DATE), infoParams}));
            } else {
                logger.warn("infoParams are not set in settings", {
                    organizationId,
                    email,
                    infoParams
                });
            }
        }
    }, [organizationId]);

    const handleChangeTag = (e, newTab) => {
        setSelectedTab(newTab);
    };

    /**
     * handler for opening the edit layer
     *
     * @param {String} opId
     * @param {String} procedureCode
     * @param {String} hcServiceId
     */
    const handleOpenEditLayer = (opId, procedureCode, hcServiceId) => {
        setOpenEditLayer(true);
        dispatch(loadEditOpAction(opId));
        dispatch(fetchOptionsAction(procedureCode, hcServiceId));
    };

    /**
     * handle to exit edit mode
     */
    const handleCloseEdit = () => {
        dispatch(clearEditOpAction());
        setOpenEditLayer(false);
    };

    const handleShowConflict = () => {
        setValue(RHF_BACKLOG_NAMES.conflictFilter, true, {shouldDirty: true});
    };

    const paramsDateRange = {
        opBacklogList,
        dateStart,
        dateEnd,
        dateFunctions: {
            fromJSDate,
            startOf,
            fromISO,
            endOf
        }
    };

    /**
     * Pre-filter the surgeries
     *    - within the date range
     *    - exclude the _status: "Appointment.waitlist" and _error.code < 400 or null
     */
    const opBacklogPreFiltered = useMemo(() => {
        // If dateRange is invalid, use last valid date
        const isDateStartValidAndNotEmpty = dateStart && isValidDate(dateStart) && fromJSDate(dateStart).isValid;
        const isDateEndValidOrEmpty = !dateEnd || (dateEnd && isValidDate(dateEnd) && fromJSDate(dateEnd).isValid);
        if (!isDateStartValidAndNotEmpty) {
            paramsDateRange.dateStart = lastValidDateStart;
        }
        if (!isDateEndValidOrEmpty) {
            paramsDateRange.dateEnd = lastValidDateEnd;
        }
        return prefilter(paramsDateRange);
    }, [opBacklogList, dateStart, dateEnd]);

    const params = {
        opBacklogList: opBacklogPreFiltered,
        selectedSurgeryNames,
        selectedOperatorNames,
        selectedRooms,
        selectedHealthcareServices,
        selectedPatientName,
        selectedPatientAgeFrom,
        selectedPatientAgeTo,
        selectedPriority,
        selectedConflict,
        allPatientNamesObject,
        allPatientBirthDateObject,
        participantCategoriesForHealthcareService
    };
    /**
     * filter the op backlog list by status
     * @param {String} statusKey
     * @return {Array<PlanBox>}
     */
    const filterByStatus = (statusKey) => {
        // @ts-ignore
        return filterOpBacklog(params).filter((op) => {
            const opStatus = opDisplayStatus(op._status, op._statusServiceRequest, op._isCutOver, op._error.code);
            return (statusKey === "all" && opStatus !== STATUS_KEY.INCONSISTENT) || opStatus === statusKey;
        });
    };

    const countPerStatus = useMemo(() => {
        return countOpBacklogPerStatus(opBacklogPreFiltered);
    }, [opBacklogPreFiltered]);

    const dateRangeLabels = {
        start: t("App.dateRange"),
        end: ""
    };
    const hasDirtyFieldsOfFilterValues = Object.keys(defaultFilterValues).some((filterName) =>
        Object.keys(dirtyFields).includes(filterName)
    );

    const toggleInconsistentTable = () => setShowInconsistentTable(!showInconsistentTable);

    const errData = filterByStatus(STATUS_KEY.INCONSISTENT);

    const isPlanToBeRequested = status.transitions?.includes(EVENTS.ManualRequestSchedulerPlanUncritical);
    return (
        <div className={classes.opBacklogRoot}>
            <InfoTicker />
            <div className={classes.titleRow}>
                <div className={classes.title}>
                    <span>{t("OpBacklogView.title")}</span>
                    <div className={classes.dateRange}>
                        <DateRangeNew
                            control={control}
                            disabled={{start: false, end: false}}
                            errors={{dateRange_start: errors.dateRange_start, dateRange_end: errors.dateRange_end}}
                            hideSwitch
                            min={minusDT(startOf(now(), "day"), "month", MIN_BACKLOG_IN_MONTH)}
                            styles={{inputDate: classes.inputDate, to: classes.to}}
                            switchDisabled={true}
                            title={dateRangeLabels}
                            values={{start: dateStart, end: dateEnd}}
                        />
                    </div>
                </div>

                {isPlanToBeRequested ? <RequestPlan /> : <Publish />}
            </div>
            <div className={classes.tableArea}>
                <TabContext value={selectedTab.toString()}>
                    <Tabs className={classes.tabs} indicatorColor="primary" value={selectedTab} onChange={handleChangeTag}>
                        {Object.keys(STATUS_KEY)
                            .filter((status) => status !== EXCLUDE_TAB)
                            .map((key) => (
                                <Tab
                                    className={classes.tab}
                                    key={STATUS_KEY[key]}
                                    label={<TabLabel count={countPerStatus[STATUS_KEY[key]]} labelKey={STATUS_KEY[key]} />}
                                />
                            ))}
                    </Tabs>
                    {Object.keys(STATUS_KEY)
                        .filter((status) => status !== EXCLUDE_TAB)
                        .map((key, index) => (
                            <TabPanel className={classes.tabPanel} key={STATUS_KEY[key]} value={index.toString()}>
                                {index === selectedTab && (
                                    <>
                                        <FiltersFormBacklog
                                            control={control}
                                            errors={errors}
                                            getValues={getValues}
                                            isDirty={hasDirtyFieldsOfFilterValues}
                                            reset={handleReset}
                                            setValue={setValue}
                                        />
                                        {/* Show inconsistencies banner only in the "Can't plan" tab */}
                                        {key === CANT_PLAN_TAB && errData?.length > 0 && (
                                            <div
                                                className={classes.inconsistent}
                                                role="button"
                                                tabIndex={0}
                                                onClick={toggleInconsistentTable}
                                                onKeyDown={(e) => e.key === "Enter" && toggleInconsistentTable()}
                                            >
                                                <Warning className={classes.errorIcon} />
                                                <Trans
                                                    components={{bold: <span className={classes.bold} />}}
                                                    i18nKey="OpBacklogView.unexpectedSurgeryStatus"
                                                    values={{count: errData.length}}
                                                />
                                            </div>
                                        )}
                                        {key === CANT_PLAN_TAB && showInconsistentTable && !!errData.length && (
                                            <OpBacklogTable
                                                alldata={opBacklogList}
                                                isBlockscreenVisible={isBlockscreenVisible}
                                                isErrorTable
                                                key={"error"}
                                                selectedTab={selectedTab}
                                                tableData={errData}
                                                onOpenEditLayer={handleOpenEditLayer}
                                                onRowClick={onRowClick}
                                                onShowConflict={handleShowConflict}
                                            />
                                        )}
                                        <OpBacklogTable
                                            alldata={opBacklogList}
                                            isBlockscreenVisible={isBlockscreenVisible}
                                            // @ts-ignore
                                            isConflictFilterSet={selectedConflict}
                                            key={"normal"}
                                            selectedTab={selectedTab}
                                            tableData={filterByStatus(STATUS_KEY[key])}
                                            onOpenEditLayer={handleOpenEditLayer}
                                            onRowClick={onRowClick}
                                            onShowConflict={handleShowConflict}
                                        />
                                    </>
                                )}
                            </TabPanel>
                        ))}
                </TabContext>
            </div>
            {openEditLayer && editOpData?.length && (
                <DetailDialog
                    isBlockscreenVisible={isBlockscreenVisible}
                    open={openEditLayer}
                    styles={{root: classes.detailDialogRoot}}
                    onClose={handleCloseEdit}
                >
                    {isPlanToBeRequested ? (
                        <RequestPlanDialog onClose={handleCloseEdit} />
                    ) : (
                        <OpEditLayer originalOpData={editOpData[0]} onClose={handleCloseEdit} />
                    )}
                </DetailDialog>
            )}
        </div>
    );
};

OpBacklogView.propTypes = {
    isBlockscreenVisible: bool,
    onRowClick: func
};

export default OpBacklogView;
