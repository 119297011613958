import {VerticalAlignBottom, VerticalAlignTop} from "@mui/icons-material";
import {Button, Drawer} from "@mui/material";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";

import {selectShowTeaser} from "../../redux/events/event_selectors";
import {filterAllowedItems} from "../../utils/menu";
import {useSecurity} from "../../utils/security";
import SupportPopover from "../support_popover/support_popover";
import {useStyles} from "./main_menu.style";
import Menu from "./menu";

/**
 * MainMenu component
 * @return {jsx}
 */
const MainMenu = ({defaultRoute, primaryMenu, userMenu, showFullMainMenu, onToggleMainMenu}) => {
    const {classes, cx} = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const {permissions} = useSecurity();
    const {t} = useTranslation();

    // redux
    const showTeaser = useSelector(selectShowTeaser);

    const currentRoute = location.pathname.length > 1 ? location.pathname : defaultRoute;

    const handleItemClick = ({name, route}, event) => {
        navigate(route);
    };

    const filteredPrimaryMenu = useMemo(() => filterAllowedItems(primaryMenu, permissions), [primaryMenu, permissions]);
    const filteredUserMenu = useMemo(() => filterAllowedItems(userMenu, permissions), [userMenu, permissions]);
    const toggleButton = (
        <Button
            className={cx({
                [classes.fullWidthButton]: showFullMainMenu,
                [classes.minWidthButton]: !showFullMainMenu
            })}
            key="toggle"
            startIcon={
                showFullMainMenu ? (
                    <VerticalAlignBottom className={cx(classes.toggleIcon, classes.icon)} />
                ) : (
                    <VerticalAlignTop className={cx(classes.toggleIcon, classes.icon)} />
                )
            }
            onClick={onToggleMainMenu}
        >
            <div className={classes.label}>{showFullMainMenu && t("OpManagementActions.close")}</div>
        </Button>
    );
    return (
        <Drawer
            anchor="left"
            classes={{
                paper: classes.drawerPaper
            }}
            className={cx(classes.drawer, {
                [classes.drawerFullWidth]: showFullMainMenu,
                [classes.drawerWithTeaser]: showTeaser
            })}
            variant="permanent"
        >
            <div className={classes.listWrapper}>
                <Menu
                    classes={{
                        icon: classes.icon,
                        iconDefault: classes.iconDefault,
                        listItem: classes.listItem,
                        listItemIcon: classes.listItemIcon,
                        listItemText: classes.listItemText
                    }}
                    currentPath={currentRoute}
                    cx={cx}
                    iconOnly={!showFullMainMenu}
                    menu={filteredPrimaryMenu}
                    onItemClick={handleItemClick}
                />
                <div>
                    {global.HELPDESK && (
                        <div className={classes.supportPopover}>
                            <SupportPopover />
                        </div>
                    )}
                    <Menu
                        classes={{
                            icon: classes.icon,
                            iconDefault: classes.iconDefault,
                            listItem: classes.listItem,
                            listItemIcon: classes.listItemIcon,
                            listItemText: classes.listItemText
                        }}
                        currentPath={currentRoute}
                        cx={cx}
                        iconOnly={!showFullMainMenu}
                        menu={filteredUserMenu}
                        onItemClick={handleItemClick}
                    />
                    <div className={classes.toggleWrapper}>{toggleButton}</div>
                </div>
            </div>
        </Drawer>
    );
};

MainMenu.propTypes = {
    defaultRoute: PropTypes.string,
    primaryMenu: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            name: PropTypes.string.isRequired,
            translationKey: PropTypes.string.isRequired,
            route: PropTypes.string.isRequired,
            pages: PropTypes.arrayOf(
                PropTypes.shape({
                    route: PropTypes.string.isRequired
                })
            )
        })
    ).isRequired,
    userMenu: PropTypes.arrayOf(
        PropTypes.shape({
            component: PropTypes.string,
            icon: PropTypes.string,
            name: PropTypes.string.isRequired,
            translationKey: PropTypes.string.isRequired
        })
    ).isRequired,
    showFullMainMenu: PropTypes.bool.isRequired,
    onToggleMainMenu: PropTypes.func.isRequired
};

MainMenu.defaultProps = {
    defaultRoute: "/"
};

export default MainMenu;
